<script lang="ts">
import { computed, defineComponent, onMounted } from 'vue';
import store from '@/store';
import NotFound from '@/components/pages/404.vue';
import Spinner from '@/components/atoms/Spinner.vue';
import Card from '@/components/molecules/Card.vue';
import Pagination from '@/components/molecules/Pagination.vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  components: {
    NotFound,
    Spinner,
    Card,
    Pagination,
  },
  emits: ['loaded'],
  setup: (props, context) => {
    const state = computed(() => store.post.state);
    const posts = computed(() => store.post.state.index);
    const postsLoading = computed(() => store.post.state.loading);
    const tagTitle = computed(() => store.tag.state.get.name);
    const title = computed(() => store.option.state.get.option_value);
    const $route = useRoute();
    const page = computed(() => {
      const matches = $route.path.match(/page\/(\d+)\/?$/);
      let page = 1;
      if (matches) {
        page = Number(matches[1] || 1);
      }

      return page;
    });
    const tag = computed(() => {
      const matches = $route.path.match(/tag\/([^/]+).*/);
      const tag = (matches && matches[1]) || '';

      return tag;
    });

    onMounted(async () => {
      const option = {
        page: page.value,
        per_page: 12,
        term: tag.value,
      };

      await Promise.all([store.option.title(), store.post.index(option), store.tag.get(tag.value)]);

      const pagination = page.value !== 1 ? `（${page.value}ページ）` : '';
      document.title = `${tagTitle.value}${pagination}｜${title.value}`;
      const $meta =
        document.querySelector<HTMLMetaElement>('meta[name="description"]') || document.createElement('meta');
      $meta.setAttribute('name', 'description');
      $meta.setAttribute('content', document.title);
      const head = document.querySelector('head');
      head?.appendChild($meta);

      loaded();
    });

    const loaded = () => {
      context.emit('loaded');
    };

    return {
      state,
      posts,
      postsLoading,
      loaded,
      page,
      tag,
      tagTitle,
    };
  },
});
</script>

<template>
  <div v-if="state.notFound" :class="$style.component">
    <NotFound />
  </div>
  <div v-else>
    <div v-if="postsLoading" :class="$style.component">
      <Spinner />
    </div>
    <section v-else>
      <h2 :class="$style.title">{{ tagTitle }}</h2>
      <ul :class="$style.posts">
        <router-link :to="`/${post.post_name}`" :class="$style.post" v-for="(post, i) in posts.data" :key="i">
          <Card :post="post" />
        </router-link>
      </ul>
      <Pagination
        v-if="posts.total > 1"
        :total="posts.total"
        :page="page"
        :perPage="posts.per_page"
        :basePath="`/tag/${tag}/page`"
        :range="2"
      />
    </section>
  </div>
</template>

<style lang="scss" module>
.component {
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  background-color: $color-light;
}

.title {
  text-align: center;
}

.posts {
  box-sizing: border-box;
  margin: 0;
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, 32%);
  grid-template-rows: auto;
  grid-gap: 16px 8px;
  justify-content: space-between;

  .post {
    box-shadow: 1px 1px 1px rgba(5, 5, 5, 0.05);

    &:hover {
      box-shadow: 2px 2px 2px rgba(5, 5, 5, 0.2);
    }
  }
}

@include mq(md) {
  .posts {
    grid-template-columns: repeat(auto-fill, 48%);
  }
}
</style>
